import useCurrentUser from "@sprout/client-graphql/dist/useCurrentUser";
import { useMemo } from "react";
import { TeamMemberLevel, UserType } from "sprout-graphql";
export default function useProfileAuthorization(user) {
    const currentUser = useCurrentUser();
    const currentUserId = currentUser === null || currentUser === void 0 ? void 0 : currentUser.id;
    const { memberLevel , id , type  } = user;
    const authorization = useMemo(()=>{
        return {
            editProfile: memberLevel === TeamMemberLevel.Owner || currentUserId === id,
            viewSettings: type === UserType.Team && (memberLevel === TeamMemberLevel.Owner || currentUserId === id),
            inviteUsers: type === UserType.Team && (memberLevel === TeamMemberLevel.Owner || memberLevel === TeamMemberLevel.Admin)
        };
    }, [
        currentUserId,
        id,
        memberLevel,
        type
    ]);
    return authorization;
};
